import React from 'react';
import classNames from 'classnames';
import css from './NotFound.module.css';
import { NamedLink } from '../../../../components';
import smileyImg from './image/smiley.png';
import thunderImg from './image/thunder.png';

const importantCities = [
  'London',
  'Paris',
  'Berlin',
  'Amsterdam',
  'Barcelona',
  'Reykjavík',
  'Edinburgh',
  'Dublin',
];

function containsImportantCity(address, cities) {
  return cities.some(city => address.includes(city));
}

const NotFound = props => {
  const { className, rootClassName, address } = props;

  const hasImportantCity = address && containsImportantCity(address, importantCities);

  const classes = classNames(rootClassName || css.root, className);
  return (
    <>
      <div className={css.messageContainer}>
        <p className={css.greeting}>Hi there!</p>
        {hasImportantCity ? (
          <div className={css.messageBody}>
            We don't have any <strong>Minders™</strong> currently onboarded in this area. <br /> But
            we do have a <strong>Global Minder™ Partner Network</strong> who can help!
            <img src={smileyImg} alt="smile" className={css.image}></img>
            <div className={css.letUsKnowContent}>
              <span>
                Click below and share details of what you need and and we'll work with them quick as
                a <img src={thunderImg} alt="thunder" className={css.image}></img> to find you great
                options.
              </span>
            </div>
          </div>
        ) : (
          <div className={css.messageBody}>
            We don't have <strong>Minders™</strong> in this area at the moment. <br /> But we LOVE
            searching <img src={smileyImg} alt="smile" className={css.image}></img>
            <div className={css.letUsKnowContent}>
              <span>
                Click below to share details of what you need and our team of{' '}
                <strong>Minder™ Finders</strong> will get right on the case!
              </span>
            </div>
          </div>
        )}
        <div className={css.letUsKnowContainer}>
          <div className={css.letUsKnowContent}>
            <NamedLink className={css.letUsKnowLink} name="NewListingPage">
              Create a Search
            </NamedLink>
          </div>
          <div />
        </div>
      </div>
    </>
  );
};

export default NotFound;
