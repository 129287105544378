import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { NamedLink } from '../../../components';

import css from './NoSearchResultsMaybe.module.css';
import NotFound from './NotFound/NotFound';

import { parse } from '../../../util/urlHelpers';

const allowedKeys = [
  'address',
  'bounds',
  'pub_categoryLevel1',
  'pub_categoryLevel2',
  'mapSearch',
  'sort',
  'timezone',
];

function checkForOtherFilters(search, allowedKeys) {
  const searchKeys = Object.keys(search);
  const hasOtherFilters = searchKeys.some(key => !allowedKeys.includes(key));
  return hasOtherFilters;
}

const NoSearchResultsMaybe = props => {
  const { listingsAreLoaded, totalItems, location, resetAll, isCurrentUserParent } = props;
  const hasNoResult = listingsAreLoaded && totalItems === 0;
  const hasSearchParams = location.search?.length > 0;
  const searchParams = parse(location.search);
  const { address } = searchParams;
  const hasOtherFilters = checkForOtherFilters(searchParams, allowedKeys);

  return hasNoResult ? (
    <div className={css.noSearchResults}>
      {hasOtherFilters || !isCurrentUserParent ? (
        <>
          <FormattedMessage id="SearchPage.noResults" />
          <br />
          {hasSearchParams ? (
            <button className={css.resetAllFiltersButton} onClick={e => resetAll(e)}>
              <FormattedMessage id={'SearchPage.resetAllFilters'} />
            </button>
          ) : null}
        </>
      ) : (
        <NotFound address={address} />
      )}
      {/*   <p>
        <NamedLink className={css.createListingLink} name="NewListingPage">
          <FormattedMessage id="SearchPage.createListing" />
        </NamedLink>
      </p> */}
    </div>
  ) : null;
};

export default NoSearchResultsMaybe;
